// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
  Textarea,
} from "../../../../_metronic/_partials/controls";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("title is required"),
  description: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("description is required"),
  neighborhood: Yup.string()
    .required("description is required"),
});

export function EditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  areas,
}) {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-6">
                    <Field
                      name="title"
                      component={Input}
                      placeholder="Title"
                      label="First Name"
                    />
                  </div>
                  {/* Last Name */}
                 
                  {/* Phone */}
                 
                </div>
             
                <div className="form-group row">
                   <div className="col-lg-6">
                    <Field
                      name="description"
                      component={Textarea}
                      placeholder="Body"
                      label="Body"
                    />
                  </div>

                  <div className="col-lg-6">
                    <Select name="neighborhood" label="Select Area To Send Noticaition" multiple>
                      <option value="">Select area</option>  
                      {areas && areas.map(d => <option value={d._id} selected={d._id === customer?.neighborhood?._id ? true : false}>{d.area}</option>)}
                     </Select>
                  </div>
                  
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
