import {createSlice} from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const countrySlice = createSlice({
  name: "customers",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // fetchCities
    countryFetched: (state, action) => {
      console.log( action.payload);
      const { totalCount,entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.entities=entities;
      state.totalCount = totalCount;
    },
    // cityCreated
    countryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.country);
    },
  }
});
