import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
//import { Area } from "./pages/area";
import { DashboardPage } from "./pages/DashboardPage";
import { NotificationPage } from "./modules/notifications/NotificationPage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
const SystemPage = lazy(() =>
  import("./modules/system/pages/systemMainPage")
);
// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// );
// const Neighbourhood = lazy(() =>
//   import("./modules/ECommerce/pages/neighbourhood/PostsPage")
// );


export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/notifications" component={NotificationPage} />
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        {/* <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        <Route path="/app" component={SystemPage} />
        {/* <Route path="/user-profile" component={UserProfilepage} /> */}
        {/* <Route path="/neighbourhoods" component={Neighbourhood} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
