import React from "react";
import { Route } from "react-router-dom";
import { PoLoadingDialog } from "./loading-dialog/LoadingDialog";
import { EditDialog } from "./edit-dialog/EditDialog";
import { DeleteDialog } from "./delete-dialog/DeleteDialog";
// import { DeleteDialog } from "./delete-dialog/DeleteDialog";
import { FetchDialog } from "./fetch-dialog/FetchDialog";
import { UpdateStateDialog } from "./update-status-dialog/UpdateStateDialog";
import { CustomersUIProvider } from "./UIContext";
import { PostsCard } from "./Card";

export function NotificationPage({ history }) {
  const customersUIEvents = {
    newCustomerButtonClick: () => {
      history.push("/notifications/new");
    },
    openEditCustomerDialog: (id) => {
      history.push(`/notifications/${id}/edit`);
    },
    openDeleteCustomerDialog: (id) => {
      history.push(`/notifications/${id}/delete`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/notifications/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/notifications/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/notifications/updateStatus");
    }
  }

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <PoLoadingDialog />
      <Route path="/notifications/new">
        {({ history, match }) => (
          <EditDialog
            show={match != null}
            onHide={() => {
              history.push("/notifications");
            }}
          />
        )}
      </Route>
      <Route path="/notifications/:id/edit">
        {({ history, match }) => (
          <EditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/notifications");
            }}
          />
        )}
      </Route>
      <Route path="/notifications/deleteCustomers">
        {({ history, match }) => (
          <DeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/notifications");
            }}
          />
        )}
      </Route>
      <Route path="/notifications/:id/delete">
        {({ history, match }) => (
          <DeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/notifications");
            }}
          />
        )}
      </Route>
      <Route path="/notifications/fetch"newCustomerButtonClick>
        {({ history, match }) => (
          <FetchDialog
            show={match != null}
            onHide={() => {
              history.push("/notifications");
            }}
          />
        )}
      </Route>
      <Route path="/notifications/updateStatus">
        {({ history, match }) => (
          <UpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/notifications");
            }}
          />
        )}

      </Route>
      <PostsCard />
    </CustomersUIProvider>
  );
}
