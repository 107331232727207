/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

       

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Users Managment</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
       
        <li
          className={`menu-item ${getMenuItemActive("/app/customers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/app/customers">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/User.svg"
                )}
              />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        


        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Posts Managment</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
       
        <li
          className={`menu-item ${getMenuItemActive("/app/posts", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/app/posts">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/User.svg"
                )}
              />
            </span>
            <span className="menu-text">Posts</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/notifications", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notifications">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Notifications1.svg"
                )}
              />
            </span>
            <span className="menu-text">Notifications</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
 
        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Custom</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/app/neighbourhoods">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Map/Position.svg"
                )}
              />
            </span>
            <span className="menu-text">Neighbourhoods</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/app/countries", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/app/Countries">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/User.svg"
                )}
              />
            </span>
            <span className="menu-text">Country</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/app/cities", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/app/cities">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/User.svg"
                )}
              />
            </span>
            <span className="menu-text">City</span>
          </NavLink>
        </li>
       
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
