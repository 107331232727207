import React from "react";
import {
  MixedWidget1,
  // MixedWidget14,
  ListsWidget9,
  // StatsWidget11,
  // StatsWidget12,
  // ListsWidget1,
  // AdvanceTablesWidget2,
  // AdvanceTablesWidget4,
  // ListsWidget3,
  // ListsWidget4,
  // ListsWidget8,
} from "../widgets";
export function Demo1Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
        {/* <div className="col-lg-6 col-xxl-4">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div> */}
      </div>
  
    </>
  );
}
