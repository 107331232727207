import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  // CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Filter } from "./filter/Filter";
import { Table } from "./table/Table";
import { Grouping } from "./grouping/Grouping";
import { useCustomersUIContext } from "./UIContext";

export function PostsCard() {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="Notifications list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            New Notification
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <Filter /> */}
        {/* {customersUIProps.ids.length > 0 && <Grouping />} */}
        <Table />
      </CardBody>
    </Card>
  );
}
